import { graphql } from 'gatsby'
import * as React from 'react'

import ApplyPage from '../components/pages/ApplyPage'
import DefaultLayout from '../layouts'

const Apply = () => (
  <DefaultLayout language="en">
    <ApplyPage />
  </DefaultLayout>
)

export default Apply

export const pageQuery = graphql`
  query ApplyQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`
